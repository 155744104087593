import {nodeFromString} from "../tools/NodeFromString";
import axios from "axios";

function initBlock(blockContainer) {
    if (blockContainer.dataset.classname && blockContainer.dataset.url) {
            let listItems = blockContainer.querySelector(`.${blockContainer.dataset.classname}-list`),
                addButton = blockContainer.querySelector(`.${blockContainer.dataset.classname}-create`),
                currentItemsCount = listItems.querySelectorAll(`.${blockContainer.dataset.classname}`).length

            function initItem(item) {
                item.querySelector(`.${blockContainer.dataset.classname}-delete`)?.addEventListener('click', () => item.remove())
                var $select = $(item.querySelectorAll('[data-toggle="select"]'));
                if ($select.length) {

                    // Init selects
                    $select.each(function() {
                        init($(this));
                    });
                }
                function init($this) {

                    $this.select2({
                        placeholder: "Select a state",
                        allowClear: true
                    });
                }
            }

            function createItem(index) {
                axios.get(blockContainer.dataset.url, {params: {index: index}})
                    .then(result => {
                        let response = result.data
                        if (response.success) {
                            let item = nodeFromString(response.data)
                            initItem(item)
                            listItems.append(item)
                        }
                    })
            }

            listItems.querySelectorAll(`.${blockContainer.dataset.classname}`).forEach(item => initItem(item))
            addButton.addEventListener('click', () => createItem(currentItemsCount++))
    } else {
        if (!blockContainer.dataset.classname) {
            console.error('Не заполнен параметр data-classname у контейнера блоков')
        }
        if (!blockContainer.dataset.url) {
            console.error('Не заполнен параметр data-url у контейнера блоков')
        }
    }
}

export {initBlock}
